import React, { useEffect } from "react";
import '../../assets/css/containers/_media.scss';
// import Arrow from '@elsdoerfer/react-arrow';
import {
    drawLineAndArrow,
    drawEclipse,
    drawText
} from '../Report/utilities';

export const PictureDecoration = (props) => {
    let imageKey = props.image?.split('/')[props.image?.split('/').length - 1];
    let dIndx = props.decorations?.findIndex(x => x[imageKey]) !== -1 ? props.decorations?.findIndex(x => x[imageKey]) : -1;
    let decorations = [];
    if(dIndx !== -1) {
        decorations = props.decorations[dIndx][imageKey];
    }
    

    return (     
        <>
           <div className="media-images-overlay">
                <svg id={`svg-design-${props.index}`} className="svg-clickable" onClick={() => {props.handleLightBox(props.index)
                props.elemData(props.image)
                }}>
                    {
                        decorations.length !== 0 ?
                        decorations.map((markings) => {
                            let imgSize = new Image();

                            imgSize.src = props.image;
                            let imgWidth = imgSize.width; 
                            let imgHeight = imgSize.height;
                            let boxWidth = 270;
                            let boxHeight = 216;

                            let boxRatio = parseFloat(boxHeight/boxWidth);
                            let imgRatio = parseFloat(imgHeight/imgWidth);

                            let originalImgWidth = imgWidth;
                            let originalImgHeight = imgHeight;
                            
                                if(document.getElementById(`svg-design-${props.index}`) !== null) {
                                    boxWidth = parseInt(document.getElementById(`svg-design-${props.index}`).width.baseVal.value);
                                    boxHeight = parseInt(document.getElementById(`svg-design-${props.index}`).height.baseVal.value);
                                }

                           
                                if(boxRatio < imgRatio) {
                                    imgHeight = boxHeight;
                                    imgWidth = parseFloat(boxHeight * parseFloat(originalImgWidth/originalImgHeight));
                                    // console.log('calculated picture dimenssions', imgWidth,'x',imgHeight);
                                  } else {
                                    imgWidth = boxWidth;
                                    imgHeight = parseFloat(boxWidth * parseFloat(originalImgHeight/originalImgWidth));
                                  }
                                let lineAndArrow = drawLineAndArrow(boxWidth, boxHeight, imgWidth, imgHeight, markings.decoration_begin_horizontal, markings.decoration_end_horizontal, markings.decoration_begin_vertical, markings.decoration_end_vertical);
                                let drawEclipseContent = drawEclipse(boxWidth, boxHeight, imgWidth, imgHeight, markings.decoration_begin_horizontal, markings.decoration_end_horizontal, markings.decoration_begin_vertical, markings.decoration_end_vertical);
                                let drawTextContent = drawText(boxWidth, boxHeight, imgWidth, imgHeight, parseInt(markings.decoration_begin_horizontal), parseInt(markings.decoration_begin_vertical), markings.decoration_text );

                                
                                return (
                                    <>
                                        {
                                            parseInt(markings.decoration_type) === 0 ?
                                            <>
                                                <line
                                                    x1={lineAndArrow.LineX1} 
                                                    y1={lineAndArrow.LineY1} 
                                                    x2={lineAndArrow.LineX2} 
                                                    y2={lineAndArrow.LineY2}
                                                    style={{ stroke: markings.decoration_color.startsWith('#') ? markings.decoration_color : `#${markings.decoration_color}`}} 
                                                    strokeWidth={lineAndArrow.strokeWidth} />
                                                <line
                                                    x1={lineAndArrow.arrowStartX1}
                                                    y1={lineAndArrow.arrowStartY1}
                                                    x2={lineAndArrow.LineX2} 
                                                    y2={lineAndArrow.LineY2}
                                                    style={{ stroke: markings.decoration_color.startsWith('#') ? markings.decoration_color : `#${markings.decoration_color}`}} 
                                                    strokeWidth={lineAndArrow.strokeWidth} />
                                                <line
                                                    x1={lineAndArrow.arrowStartX2}
                                                    y1={lineAndArrow.arrowStartY2}
                                                    x2={lineAndArrow.LineX2} 
                                                    y2={lineAndArrow.LineY2}
                                                    style={{ stroke: markings.decoration_color.startsWith('#') ? markings.decoration_color : `#${markings.decoration_color}`}} 
                                                    strokeWidth={lineAndArrow.strokeWidth} />
                                            </> : parseInt(markings.decoration_type) === 1 ?
                                            <>
                                                <ellipse
                                                    fill="none"
                                                    cx={Math.abs(drawEclipseContent.Vcx)}
                                                    cy={Math.abs(drawEclipseContent.Vcy)}
                                                    rx={Math.abs(drawEclipseContent.Vrx === 0 ? 1 : drawEclipseContent.Vrx)}
                                                    ry={Math.abs(drawEclipseContent.Vry)}
                                                    style={{ stroke: markings.decoration_color.startsWith('#') ? markings.decoration_color : `#${markings.decoration_color}`}} 
                                                    strokeWidth={drawEclipseContent.strokeWidth}
                                                />
                                            </> 
                                    :  parseInt(markings.decoration_type) === 3 ?
                                    <>
                                    
                                            <>
                                              
                                               {
                                               drawTextContent?.textPlacements?.lineTexts?.map((text, index) => (
                                                <text
                                                id='getText1'
                                                x={drawTextContent?.textPlacements?.placements[index].x} 
                                                y={drawTextContent?.textPlacements?.placements[index].y} 
                                                fill={markings.decoration_color}
                                                className='svg__fonts'
                                                style={{
                                                    fontSize: drawTextContent?.textPlacements?.fontSize,
                                                    fontWeight: 500,
                                                    strokeWidth:0.3,
                                                    strokeLinejoin:'round', 
                                                    strokeLinecap: 'round'
                                                }}
                                                >
                                                    {text}
                                                </text>
                                               ))
                                              }
                                            </> : (
                                                ""
                                            )
                                           </>
                                        
                                        : ""
                                    
                                        }
                                 
                                    </>
                                )
                            })
                        : ""
                    }
                </svg>
                 <img src={props.image} className="media-images"  onClick={() => {props.handleLightBox(props.index)
                    props.elemData(props.image)
                }} alt={`pal-tech-images-${props.image}`}/>
            </div>
        </>
    );
}
