
const url_settings = {
    'integration.palm-tech.com' : {
        SERVER_URL: 'https://portalbkup.palm-tech.com'
    },
    'localhost:3000' : {
        SERVER_URL: 'https://develop.palm-tech.com',
    },
    'localhost:3001' : {
        SERVER_URL: 'https://portalbkup.palm-tech.com'
    },
    'develop.yourinspection.report': {
        SERVER_URL: 'https://develop.palm-tech.com'
    },
    'integration.yourinspection.report' : {
        SERVER_URL: 'https://integration.palm-tech.com'
    },
    'portalbkup.yourinspection.report' : {
        SERVER_URL: 'https://portalbkup.palm-tech.com'
    },
    'staging.yourinspection.report' : {
        SERVER_URL: 'https://staging.palm-tech.com'
    },
    'yourinspection.report' : {
        SERVER_URL: 'https://portal.palm-tech.com'
    }
}

export const forgotPasswordUrl = {
    'integration.palm-tech.com' : {
        FORGOT_PASSWORD_URL: 'https://portalbkup.palm-tech.com/sendForgotPasswordEmail'
    },
    'localhost:3000' : {
        FORGOT_PASSWORD_URL: 'https://develop.palm-tech.com/sendForgotPasswordEmail',
    },
    'localhost:3001' : {
        FORGOT_PASSWORD_URL: 'https://integrationvanguard.us-east-1.elasticbeanstalk.com/public/index.php/api/password/remind'
    },
    'develop.yourinspection.report': {
        FORGOT_PASSWORD_URL: 'https://develop.palm-tech.com/sendForgotPasswordEmail',
    },
    'integration.yourinspection.report' : {
        FORGOT_PASSWORD_URL: 'https://integration.palm-tech.com/sendForgotPasswordEmail',        
    },
    'portalbkup.yourinspection.report' : {
        FORGOT_PASSWORD_URL: 'https://portalbkup.palm-tech.com/sendForgotPasswordEmail',        
    },
    'staging.yourinspection.report' : {
        FORGOT_PASSWORD_URL: 'https://staging.palm-tech.com/sendForgotPasswordEmail',
    },
    'yourinspection.report' : {
        FORGOT_PASSWORD_URL: 'https://portal.palm-tech.com/sendForgotPasswordEmail',
    }
}

// demo user static parameters
export const demo_user_settings = {
    'localhost:3000' : {
        DEMO_USER_GUID:'fceb82cd-48e9-4db4-8d3a-832e24338987',
        DEMO_USER_PARAM_0: '0701',
        DEMO_USER_PARAM_1: '4jGAqfSQ6NKl0OSUUKQoKovQYlX',
        DEMO_USER_PARAM_2: 'tXnz0r63TFVjNZEzxfNQoLW',
        DEMO_USER_PARAM_3: 'insp',
        DEMO_USER_PARAM_4: '',
        appointmentId:'10296',
        DEMO_USER_SHARE_STATIC_LINK:'/preview-report?appointmentId=10309&id1=0117&id2=HnpqhsPjlvThFV1uZ17jZBQiR&id3=2tDI6yxjrHvLNa0PCzn5hS1hWO8e',
        DEMO_USER_SHARE_PARAM_1:'HnpqhsPjlvThFV1uZ17jZBQiR',
        DEMO_USER_SHARE_PARAM_2:'2tDI6yxjrHvLNa0PCzn5hS1hWO8e'
    },
    'develop.yourinspection.report': {
        DEMO_USER_GUID:'fceb82cd-48e9-4db4-8d3a-832e24338987',
        DEMO_USER_PARAM_0: '0701',
        DEMO_USER_PARAM_1: '4jGAqfSQ6NKl0OSUUKQoKovQYlX',
        DEMO_USER_PARAM_2: 'tXnz0r63TFVjNZEzxfNQoLW',
        DEMO_USER_PARAM_3: 'insp',
        DEMO_USER_PARAM_4: '',
        appointmentId:'10296',
        // static report link for the create request list preview
        DEMO_USER_SHARE_STATIC_LINK:'/preview-report?appointmentId=10309&id1=0117&id2=HnpqhsPjlvThFV1uZ17jZBQiR&id3=2tDI6yxjrHvLNa0PCzn5hS1hWO8e',
        DEMO_USER_SHARE_PARAM_1:'HnpqhsPjlvThFV1uZ17jZBQiR',
        DEMO_USER_SHARE_PARAM_2:'2tDI6yxjrHvLNa0PCzn5hS1hWO8e'
    },
    'integration.yourinspection.report' : {
        DEMO_USER_GUID:'16b142a8-4594-4c2e-a343-59d2475215a8',
        DEMO_USER_PARAM_0: '0603',
        DEMO_USER_PARAM_1: 'jtVb1FcFnGxyeyy0swiaLrRyp',
        DEMO_USER_PARAM_2: 'lAjGgq7EwdSypCd9AGLWDxAO.',
        DEMO_USER_PARAM_3: 'insp',
        DEMO_USER_PARAM_4: '',
        appointmentId:'11055',
        DEMO_USER_SHARE_STATIC_LINK:'/preview-report?appointmentId=10783&id1=0119&id2=6sD6OuWnqvU5gCzCcPACGxA&id3=fMjAuwRHfdlG2cvCdCvDKc8PU8&id4=insp.&e=null',
        DEMO_USER_SHARE_PARAM_1:'6sD6OuWnqvU5gCzCcPACGxA',
        DEMO_USER_SHARE_PARAM_2:'fMjAuwRHfdlG2cvCdCvDKc8PU8'        
    },
    'portalbkup.yourinspection.report' : {
        DEMO_USER_GUID:'16b142a8-4594-4c2e-a343-59d2475215a8',
        DEMO_USER_PARAM_0: '0603',
        DEMO_USER_PARAM_1: 'jtVb1FcFnGxyeyy0swiaLrRyp',
        DEMO_USER_PARAM_2: 'lAjGgq7EwdSypCd9AGLWDxAO.',
        DEMO_USER_PARAM_3: 'insp',
        DEMO_USER_PARAM_4: '',
        appointmentId:'11055',
        DEMO_USER_SHARE_STATIC_LINK:'/preview-report?appointmentId=10783&id1=0119&id2=6sD6OuWnqvU5gCzCcPACGxA&id3=fMjAuwRHfdlG2cvCdCvDKc8PU8&id4=insp.&e=null',
        DEMO_USER_SHARE_PARAM_1:'6sD6OuWnqvU5gCzCcPACGxA',
        DEMO_USER_SHARE_PARAM_2:'fMjAuwRHfdlG2cvCdCvDKc8PU8'        
    },
    'staging.yourinspection.report' : {
        DEMO_USER_GUID:'f80e004a-5192-4458-b15c-661bb6c4bcf7',
        DEMO_USER_PARAM_0: '000',
        DEMO_USER_PARAM_1: 'wHdXAQgW0JdnLRGVMlrZRE5oBs',
        DEMO_USER_PARAM_2: '0VTadZpSnmO1kFL10iVTGHzf6baTD',
        DEMO_USER_PARAM_3: 'insp',
        DEMO_USER_PARAM_4: '',
        appointmentId:'10822',
        // static report link for the create request list preview
        DEMO_USER_SHARE_STATIC_LINK:'/preview-report?appointmentId=10946&id1=0704&id2=KMhpvLL6GeLpUyMTyan02YN&id3=sIiWHrwqXvQwihpues6YI9PG5U&id4=insp&e=null',
        DEMO_USER_SHARE_PARAM_1:'KMhpvLL6GeLpUyMTyan02YN',
        DEMO_USER_SHARE_PARAM_2:'sIiWHrwqXvQwihpues6YI9PG5U'
    },
    'yourinspection.report' : {
        DEMO_USER_GUID:'{A8F65DCB-22F2-4575-84A1-51DED6B99E25}',
        DEMO_USER_PARAM_0: '000',
        DEMO_USER_PARAM_1: 'XuLwOkNPfOCv1agQaRRPu',
        DEMO_USER_PARAM_2: 'dtOPHCOE6WE5JNbhV3MjV',
        DEMO_USER_PARAM_3: 'insp',
        DEMO_USER_PARAM_4: '',
        appointmentId:'238079',
        DEMO_USER_SHARE_STATIC_LINK:'/preview-report?appointmentId=177290&id1=0119&id2=2x7ez9NOSVUMN2JVTEVfcN24&id3=TsjWgkQCwQRO6acl0koEjr7l&id4=insp&e=null',
        DEMO_USER_SHARE_PARAM_1:'2x7ez9NOSVUMN2JVTEVfcN24',
        DEMO_USER_SHARE_PARAM_2:'TsjWgkQCwQRO6acl0koEjr7l'
    }
}
export default url_settings;

