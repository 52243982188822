
export const color = {
    'Acceptable': '#0F8CFF',
    'Applicable': '#0F8CFF',
    'Marginal': '#6977DF',
    'Defective': '#26308E',
    'Deficient': '#26308E',
    'Not Inspected': '#71B8F0',
    'Not Present': '#678AAB',
    'Not Applicable': '#678AAB',
    'null': 'transparent',
    'repairSelected': '#0ead7c',
    'extra-0': '#469fae',
    'extra-1': '#a443ad',
    'extra-2': '#20c0ff',
    'extra-3': '#4adcbe',
    'extra-4': '#20c0ff',
    'extra-5': '#219f8c',
    'extra-6': '#0F8CFF',
    'extra-7': '#6977DF',
    'extra-8': '#26308E',
    'extra-9': '#678AAB',
    'extra-10': '#0ead7c'
}