import React,{useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../assets/css/buttons/_buttons.scss";
import "../../assets/css/containers/_navmenu.scss";
import { NavLink } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { Link as Scroll } from "react-scroll";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "react-responsive";
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from "@mui/icons-material/Print";

const Navmenu = (props) => {  
  const isTabOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const isMobileBreakPoint = useMediaQuery({ query: "(max-width: 600px)" });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [menuName,setMenuName] = useState('Select Categories')

  // download pdf
  function download_file(fileURL, fileName) {
   let link;
   //Set the File URL.
   var url = `${fileURL}/${fileName}`;
   //Create XMLHTTP Request.
   var req = new XMLHttpRequest();
   req.open("GET", url, true);
   req.responseType = "blob";
   req.onload = function () {
       //Convert the Byte Data to BLOB object.
       var blob = new Blob([req.response], { type: "application/octetstream" });
       //Check the Browser type and download the File.
       var isIE = false || !!document.documentMode;
       if (isIE) {
           window.navigator.msSaveBlob(blob, fileName);
       } else {
           var url = window.URL || window.webkitURL;
           link = url.createObjectURL(blob);
           var a = document.createElement("a");
           a.setAttribute("download", fileName);
           a.setAttribute("href", link);
           document.body.appendChild(a);
           a.click();
           document.body.removeChild(a);
       }
   };
   req.send(); 
  }
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (activeCategory) => {
    if (typeof activeCategory === 'string' || activeCategory instanceof String)
    setMenuName(activeCategory)
    else setMenuName('Select Categories');
    setAnchorEl(null);
  };
  const handleFilterCondition = (data) => {
    if (props.showSummaryReport) {
      return parseInt(data.showOnSummary) !== 0;
    } else if (props.showRepairReport) {
      return parseInt(data.showOnSummary) !== 0;
    } else if (props.showMedia) {
      return parseInt(data.showOnSummary) !== 0;
    } else {
      return data;
    }
  };
  const pathName = window.location.pathname.split("/")[1];
  return (
    <>
      <Box className={isTabOrMobile?"sticky":""}>
        {!props.showRepairReport?
         <Grid className="navmenu-container" container alignItems="center">
          <Grid item xs={12} md={7} lg={6}>
            <Paper elevation={0} style={{ marginLeft: "20px" }}>
            {isTabOrMobile?
             (
              <>
            <NavLink
                to="/full-report"
                className={`list-item-small ${window.location.pathname !== "/full-report"? null : "nav-link"}`}
              >
                Full Report
              </NavLink>
              <NavLink
                to="/summary-report"
                className={`list-item-small ${window.location.pathname !== "/summary-report"? null : "nav-link"}`}               
              >
                Summary
              </NavLink>
              <NavLink
                to="/pdf-report"
                className={`list-item-small ${window.location.pathname !== "/pdf-report"? null : "nav-link"}`}               
              >
                Pdf
              </NavLink>
              <NavLink
                to="/media"
                className={`list-item-small ${window.location.pathname !== "/media"? null : "nav-link"}`}
              >
                Media
              </NavLink>
              </>)
            :
            (
              <>
            <NavLink
                to="/full-report"
                className={`list-item ${window.location.pathname !== "/full-report"? null : "nav-link"}`}
               
              >
                Full Report
              </NavLink>
              <NavLink
                to="/summary-report"
                className={`list-item ${window.location.pathname !== "/summary-report"? null : "nav-link"}`}               
              >
                Summary
              </NavLink>
              <NavLink
                to="/pdf-report"
                className={`list-item ${window.location.pathname !== "/pdf-report"? null : "nav-link"}`}               
              >
                Pdf
              </NavLink>
              <NavLink
                to="/media"
                className={`list-item ${window.location.pathname !== "/media"? null : "nav-link"}`}
              >
                Media
              </NavLink>              
              </>)
            }
              
            </Paper>
            <div></div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={6}
            display={{ xs: "none", md: "none", lg: "flex" }}
            className="nav-right"
          >
            {!props.showRepairReport && !props.showMedia && !props.showPDFReport &&
            <>
            <span onClick={()=>{download_file(props.assetUrl, props.pdf_name) }}><DownloadIcon/>Download PDF</span>
              <span onClick={props.handlePrint} ><PrintIcon/>Print this view</span>
              </>
            }
          </Grid>
        </Grid>
        :""}
       {!props.showPDFReport? <Grid container>
          <Grid            
            className="dropdown-category"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display={{ xs: "block", md: "block", lg: "none" }}
          >
            {/* categories    */}
            <Box
              className=""
              display="flex"
              alignItems="center"
              height="inherit"
              sx={{ flexGrow: 1 }}
            >
              <Grid container spacing={1} className="nav-right-responsive">
                <Grid item>
                  <div>
                    <div
                      id="demo-positioned-button"
                      aria-controls="demo-positioned-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <AssignmentOutlinedIcon fontSize="small" />
                        <span className={props.isLocationTexas?"category-title cat-texas":"category-title cat-normal"}>
                         {menuName?.includes('#categoryid#')?menuName?.split('#categoryid#')[0]:menuName}
                        </span>{" "}
                        <KeyboardArrowDownIcon />
                      </span>
                    </div>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClick={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {props.categories.length > 0
                      ?pathName === "full-report"?
                       props.categories.filter(handleFilterCondition)
                       .map((cat, cindex) => {
                        let cntIdx = props.countObj
                        ? props.countObj.findIndex((x) => x[cat.categoryName])
                        : "";
                        return (
                            cat.categoryName !== "General Information" &&
                            cat.categoryName !== "Definitions" &&
                            cat.categoryName !== "Inspection Details" ? (

                      <MenuItem
                      key={`${cindex}-${cat.categoryName}`}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          <Scroll
                            to={`${cat.categoryName}${cat.categoryId}`}
                            spy={true}
                            smooth={true}                            
                            style={{ color: "black" }}
                            onClick={()=>handleClose(cat.categoryName)}
                            className="list-menu"
                            offset={pathName !=='repair-list'?-140:-70}
                          >
                            <span> 
                                    {isMobileBreakPoint?
                                    cat.categoryName?.includes('#categoryid#')?
                                    cat.categoryName?.split('#categoryid#')[0]?.substring(0,36):cat.categoryName?.substring(0,36):
                                    cat.categoryName?.includes('#categoryid#')?  cat.categoryName?.split('#categoryid#')[0]?.substring(0,50):
                                    cat.categoryName?.substring(0,50)}
                             </span>
                            {props.showRepairReport ? (
                              <div className="navmenu-sidebar-icon-badge">
                                <span className="navmenu-sidebar-icon-badge-count">
                                  0
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </Scroll>
                        </ListItemText>
                      </MenuItem>):"" )
                      }):
                      props.categories.filter(handleFilterCondition)
                      .map((cat, cindex) => {
                       let cntIdx = props.countObj
                       ? props.countObj.findIndex((x) => x[cat.categoryName])
                       : "";                      
                        return ( cat.is_category_empty === "1" &&
                            cat.categoryName !== "General Information" &&
                            cat.categoryName !== "Definitions" &&
                            cat.categoryName !== "Inspection Details" ? (

                      <MenuItem
                      key={`${cindex}-${cat.categoryName}`}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          <Scroll
                            to={`${cat.categoryName}${cat.categoryId}`}
                            spy={true}
                            smooth={true}                            
                            style={{ color: "black" }}
                            onClick={()=>handleClose(cat.categoryName)}
                            offset={pathName !=='repair-list'?-140:-70}
                          >
                           <span style={{marginRight:'10px'}}> 
                                    {isMobileBreakPoint?
                                    cat.categoryName?.includes('#categoryid#')?
                                    cat.categoryName?.split('#categoryid#')[0]?.substring(0,36):cat.categoryName?.substring(0,36):
                                    cat.categoryName?.includes('#categoryid#')?  cat.categoryName?.split('#categoryid#')[0]?.substring(0,50):
                                    cat.categoryName?.substring(0,50)}
                           </span>
                            {/* comment */}
                            {props.showRepairReport ? (
                                    <div
                                      key={`badge-${cat.categoryName}`}
                                      className={
                                        cntIdx !== ""
                                          ? cntIdx !== -1
                                            ? props.countObj[cntIdx][
                                                cat.categoryName
                                              ][0].count === 0
                                              ? "report-sidebar-icon-badge"
                                              : "report-sidebar-icon-badge-active"
                                            : "report-sidebar-icon-badge"
                                          : "report-sidebar-icon-badge"
                                      }
                                    >
                                      <span
                                        key={`badge-count-${cat.categoryName}`}
                                        className="report-sidebar-icon-badge-count-light"
                                      >
                                        {cntIdx !== ""
                                          ? cntIdx !== -1
                                            ? props.countObj[cntIdx][
                                                cat.categoryName
                                              ][0].count
                                            : 0
                                          : 0}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                            {/* comment */}
                          </Scroll>
                        </ListItemText>
                      </MenuItem>):"" )
                      }):""}
                    </Menu>
                  </div>
                </Grid>
                {!props.showRepairReport && !props.showMedia && !props.showPDFReport &&
                 <Grid className="print_elem">
                <span onClick={()=>{download_file(props.assetUrl, props.pdf_name) }}><DownloadIcon/>{!isMobileBreakPoint && "Download PDF"}</span>
              <span onClick={props.handlePrint} ><PrintIcon/>{!isMobileBreakPoint && "Print this view"}</span>
                </Grid>
                }
               
              </Grid>
            </Box>
            {/* categories    */}
          </Grid>
        </Grid>:""}
       
      </Box>
    </>
  );
};

export default Navmenu;