import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../assets/css/buttons/_buttons.scss";
import "../../assets/css/containers/_drawer.scss";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { RepairReportView } from "../RepairReportView/RepairReportView";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { drawerContentCounts } from "../Report/utilities";

const Drawer = (props) => {
  const [isRepairReport, setisRepairReport] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [drawerContent, setDrawerContent] = useState({});
  const [showUnsavedPreviewModal,setShowUnsavedPreviewModal] = useState(false);
  const [showUnsavedFilterModal,setShowUnsavedFilterModal] = useState(false);
  const [showUnsavedbuilReqCloseModal,setShowUnsavedbuilReqCloseModal] = useState(false);
  const [showUnsavedRepairListModal,setShowUnsavedRepairListModal] = useState(false);
  const [lastHandlerChange,setLastHandlerChanged] = useState({value:'', flag:'',e:''})
  const navigate = useNavigate();
  const showRepairReport = (value) => {
    setisRepairReport(value);
  };

  useEffect(() => {
    let drawerCounts = drawerContentCounts(props.reportContent);
    setDrawerContent(drawerCounts);
  }, [props]);

  
// check for unsaved values on filter ratings
const unsavedDataCheck = (value, flag,e) => {
  let commentStatusArray = [];
  let dollarStatusArray = [];
  let comtbox = document.querySelectorAll(".textarea-wrapper");
  let dollarInput = document.querySelectorAll(".inputfield-wrapper");
  comtbox.forEach((elem) => {
    let boxStyle = elem.firstElementChild.getAttribute("style");
    if (boxStyle === "display: block;") commentStatusArray.push("true");
    else  commentStatusArray.push("false");
  });
  dollarInput.forEach((dollarElem) => {
    let dollarStyle = dollarElem.firstElementChild.getAttribute("style");
    if (dollarStyle === "display: block;") dollarStatusArray.push("true")
    else dollarStatusArray.push("false");  
  });
  if ((commentStatusArray.length !== 0 && commentStatusArray.includes("true")) ||
    (dollarStatusArray.length !== 0 && dollarStatusArray.includes("true"))) {
    setShowUnsavedPreviewModal(true);
    return {returnFlag:true ,value, flag,e}
  } else {
    setShowUnsavedPreviewModal(false)
    return {returnFlag:false ,value, flag,e}
  }
};

  const changeHandler = (value, flag,e) => {
    let unsavedStatusFlagObj ={returnFlag:false};
    unsavedStatusFlagObj = unsavedDataCheck(value, flag,e)
    setLastHandlerChanged({value, flag,e})
    // filter data only if there is no unsaved comments
    if(unsavedStatusFlagObj.returnFlag === false){
    setActiveFilters({
      ...activeFilters,
      [value]: flag,
    });
    if (value === "Acceptable") props.filteredFlags(value, flag);
    if (value === "Defective") props.filteredFlags(value, flag);
    if (value === "Marginal") props.filteredFlags(value, flag);
    if (value === "Not Inspected") props.filteredFlags(value, flag);
    if (value === "Not Present") props.filteredFlags(value, flag);
    if (value === "Safety") props.filteredFlags(value, flag);
    else props.filteredFlags(value, flag);
  } else {
    e.target.checked = !e.target.checked
  }
  };

  // check for unsaved comments and cost while navigating to preview
  const navigatePreview = (action) => {
    let commentStatusArray = [];
    let dollarStatusArray = [];
    let comtbox = document.querySelectorAll(".textarea-wrapper");
    let dollarInput = document.querySelectorAll(".inputfield-wrapper");
    comtbox.forEach((elem) => {
      let boxStyle = elem.firstElementChild.getAttribute("style");
      if (boxStyle === "display: block;") {
        commentStatusArray.push("true");
      } else {
        commentStatusArray.push("false");
      }
    });
    dollarInput.forEach((dollarElem) => {
      let dollarStyle = dollarElem.firstElementChild.getAttribute("style");
      if (dollarStyle === "display: block;") dollarStatusArray.push("true");
       else dollarStatusArray.push("false");
    });
    if (
      (commentStatusArray.length !== 0 && commentStatusArray.includes("true")) ||
      (dollarStatusArray.length !== 0 && dollarStatusArray.includes("true"))
    ){
      if(action === "preview")setShowUnsavedFilterModal(true)
      else if(action === "full-report") setShowUnsavedbuilReqCloseModal(true)
      else if(action ==="create") setShowUnsavedRepairListModal(true)
    } 
     else {
      if(action==='preview'){
        setShowUnsavedFilterModal(false)
         navigate("/preview-report", {
        state: {
          appointmentId: props.appointmentId,
          assetUrl: props.assetUrl,
          inspectionDetails: props.inspectionDetails,
          isDemoUser: props.isDemoUser,
        },
      });
      }
      else if(action === "full-report") {
        setShowUnsavedbuilReqCloseModal(false) 
        navigate("/full-report") 
      } 
      else if(action === "create"){
        setShowUnsavedRepairListModal(false)
        setisRepairReport(!isRepairReport)
      }
    }
  };

  // const handleDefaultCheck = (filters, rating) => {
  //   console.log(activeFilters);
  //   if(filters?.includes(rating)) {
  //     setActiveFilters({
  //       ...activeFilters,
  //       [rating]: true
  //     });
  //   } else {
  //     setActiveFilters({
  //       ...activeFilters,
  //       [rating]: false
  //     });
  //   }
  // }

  // useEffect(() => {
  //   let ratObj = {};
  //   props.ratingsInfo.forEach((rating) => {
  //     let key = rating.ratingName;
  //     if(Object.keys(ratObj)[0] !== key) {
  //       ratObj[key] = props.activeFilters.includes(key);
  //     } else {
  //       ratObj[key] = props.activeFilters.includes(key);
  //     }
  //   });

  //   setActiveFilters(ratObj);
  // }, [props.activeFilters, props.ratingsInfo]);

  return (
    <>
      {isRepairReport ? (
        <RepairReportView
          hideModal={showRepairReport}
          reportUrl={props.assetUrl}
          senderEmail={props.senderEmail}
          reportviewUrl={props.reportviewUrl}
          previewReportViewUrl={props.previewReportViewUrl}
          isDemoUser={props.isDemoUser}
          appointmentId={props.appointmentId}
          assetUrl={props.assetUrl}
          inspectionDetails={props.inspectionDetails}
        />
      ) : (
        ""
      )}
      {/* preview unsaved alert popup */}
      {showUnsavedFilterModal && 
        <SweetAlert
          style={{ width: "40em",padding:'40px' }}
          // title="Alert"
          className="login-alert-title"
          primary
          customButtons={
            <div className="drawer-cta-container">
              <button onClick={(e)=>{props.handleUnsavedChanges(e,"preview");setShowUnsavedFilterModal(false); }}
                className="default-button"
              >
                Save
              </button>
              <button 
                onClick={() => {props.cancelChanges();setShowUnsavedFilterModal(false);
                  navigate("/preview-report", {
                    state: {
                      appointmentId: props.appointmentId,
                      assetUrl: props.assetUrl,
                      inspectionDetails: props.inspectionDetails,
                      isDemoUser: props.isDemoUser,
                    },
                  })
                }}
                 className="default-button">Abandon</button>
              <button 
                onClick={() => setShowUnsavedFilterModal(false)}
                 className="default-button">Cancel</button>
            </div>
          }
        >
          <span className="sweet-alert-innertext" style={{display:'block',paddingBottom:'20px'}}>
            You have unsaved changes. Do you want to save your changes?
          </span>
        </SweetAlert>
      }
       {/* filter unsaved changes alert popup */}
       {showUnsavedPreviewModal &&
        <SweetAlert
          style={{ width: "40em" }}
          // title="Alert"
          className="login-alert-title"
          primary
          customButtons={
            <div className="drawer-cta-container">
              <button onClick={(e)=>{props.handleUnsavedChanges(e,"filter");setShowUnsavedPreviewModal(false) }}
                className="default-button">
                Save
              </button>
              <button 
                onClick={() => {props.cancelChanges();setShowUnsavedPreviewModal(false);lastHandlerChange.e.target.click()}}
                 className="default-button">Abandon</button>
              <button 
                onClick={() => setShowUnsavedPreviewModal(false)}
                 className="default-button">Cancel</button>
            </div>
          }>
          <span className="sweet-alert-innertext" style={{display:'block',paddingBottom:'20px'}}>
          You have unsaved changes. Do you want to save your changes?
          </span>
        </SweetAlert>
      }
       {/* unsaved changes alert popup on closing build request */}       
       {showUnsavedbuilReqCloseModal &&
        <SweetAlert
          style={{ width: "40em",padding:'40px' }}
          // title="Alert"
          className="login-alert-title"
          primary
          customButtons={
            <div className="drawer-cta-container">
              <button onClick={(e)=>{props.handleUnsavedChanges(e,"full-report");setShowUnsavedbuilReqCloseModal(false); }}
                className="default-button"
              >
                Save
              </button>
              <button 
                onClick={() => {props.cancelChanges();setShowUnsavedbuilReqCloseModal(false);
                  navigate("/full-report")
                }}
                 className="default-button">Abandon</button>
              <button 
                onClick={() => setShowUnsavedbuilReqCloseModal(false)}
                 className="default-button">Cancel</button>
            </div>
          }
        >
          <span className="sweet-alert-innertext" style={{display:'block',paddingBottom:'20px'}}>
            You have unsaved changes. Do you want to save your changes?
          </span>
        </SweetAlert>
     }
       {/* unsaved changes alert popup on create request */}     
      {showUnsavedRepairListModal &&
            <SweetAlert
              style={{ width: "40em",padding:'40px' }}
              // title="Alert"
              className="login-alert-title"
              primary
              customButtons={
                <div className="drawer-cta-container">
                  <button onClick={(e)=>{props.handleUnsavedChanges(e,"create");setShowUnsavedRepairListModal(false);setisRepairReport(!isRepairReport) }}
                    className="default-button"
                  >
                    Save
                  </button>
                  <button 
                    onClick={() => {props.cancelChanges();setShowUnsavedRepairListModal(false);setisRepairReport(!isRepairReport)
                    }}
                    className="default-button">Abandon</button>
                  <button 
                    onClick={() => setShowUnsavedRepairListModal(false)}
                    className="default-button">Cancel</button>
                </div>
              }
            >
              <span className="sweet-alert-innertext" style={{display:'block',paddingBottom:'20px'}}>
                You have unsaved changes. Do you want to save your changes?
              </span>
            </SweetAlert>
         }
            <Box className="drawer-container">
        <Grid
          className="drawer-head"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <span className="drawer-head-text">Create a Request List</span>
          <div className="close-icon">
            {/* <NavLink to="/full-report"> */}
              <CloseIcon onClick={()=>navigatePreview("full-report")} style={{ color: "#3B3B3B",cursor:'pointer' }} />
            {/* </NavLink> */}
          </div>
        </Grid>
        <Grid container className="drawer-content-container">
          <Grid item xs={12} sm={12} md={2} lg={2}>
            {/* blank content */}
          </Grid>
          <Grid className="checkbox-area" item xs={12} sm={12} md={5} lg={5}>
            <Grid container>
              {" "}
              <span className="checkbox-heading"> SHOW FINDINGS</span>
            </Grid>
            <Grid container>
              {props.ratingsInfo?.map((checkboxItm, key) => {
                // console.log(props.activeFilters);
                return (
                  <>
                    {props.activeFilters !== "" ? (
                      <FormControlLabel
                        key={`checkbox-${checkboxItm.ratingName}-${key}`}
                        className="checkbox-control"
                        control={
                          <Checkbox
                            id="checkbox-default-check"
                            defaultChecked={
                              props.activeFilters?.includes(
                                checkboxItm.ratingName
                              )
                                ? true
                                : false
                            }
                            value={checkboxItm.ratingName}
                            onClick={(e) =>
                              changeHandler(e.target.value, e.target.checked,e)
                            }
                            sx={{
                              color: "#000",
                              "&.Mui-checked": { color: "#000" },
                            }}
                          />
                        }
                        label={
                          <Typography className="label-text">
                            {`${checkboxItm.ratingName} (${
                              checkboxItm.ratingName === "Acceptable"
                                ? drawerContent.acceptableCnt
                                : checkboxItm.ratingName === "Defective"
                                ? drawerContent.defectiveCnt
                                : checkboxItm.ratingName === "Not Present"
                                ? drawerContent.notPresentCnt
                                : checkboxItm.ratingName === "Safety"
                                ? drawerContent.safetyCnt
                                : checkboxItm.ratingName === "Not Inspected"
                                ? drawerContent.notInspectedCnt
                                : checkboxItm.ratingName === "Marginal"
                                ? drawerContent.marginalCnt
                                : drawerContent.otherRatings?.findIndex(
                                    (x) => x[checkboxItm.ratingName]
                                  ) !== -1
                                ? drawerContent.otherRatings
                                  ? Object.values(
                                      drawerContent.otherRatings[
                                        drawerContent.otherRatings?.findIndex(
                                          (x) => x[checkboxItm.ratingName]
                                        )
                                      ]
                                    )[0]
                                  : ""
                                : ""
                            })`}{" "}
                          </Typography>
                        }
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} className="items-content">
            <Grid style={{ marginBottom: "5px" }}>
              <span className="build-items">
                {" "}
                Total items <br /> Selected:
              </span>{" "}
              <span className="build-rate"> {props.repairCount}</span>
            </Grid>
            <Grid>
              <span className="build-cost"> Total Repair Cost : </span>
              <br /> <span className="build-rate"> ${props.repairTtlAmt}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} className="drawer-buttons">
            {props.repairCount === 0 ? (
              <Button
                disabled
                className="build-req-preview-button-disabled"
                variant="outlined"
              >
                Preview
              </Button>
            ) : (
              <Button
                onClick={()=>navigatePreview("preview")}
                className="build-req-preview-button"
                variant="outlined"
              >
                Preview
              </Button>
            )}
            <Button
              disabled={props.repairCount === 0 ? true : false}
              className={
                props.repairCount === 0
                  ? "build-req-create-button-disabled"
                  : "build-req-create-button"
              }
              variant="contained"
              onClick={() => navigatePreview("create")}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Drawer;
