import React, { useEffect, useState } from "react";
import "../../assets/css/containers/_media.scss";
// import Arrow from '@elsdoerfer/react-arrow';
import { drawLineAndArrow, drawEclipse, drawText } from "../Report/utilities";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ZoomDecoration = (props) => {

  const { img, picture_decoration, currIndx } = props;
  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();

  const matches = useMediaQuery("(min-width:600px)");

  const getMeta = (url, callback) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      callback(this.width, this.height);
    };
  };

  getMeta(img, (width, height) => {
    setImageWidth(width);
    setImageHeight(height);
  });

  let imageKey = img?.split("/")[img?.split("/").length - 1];
  let dIndx =
    picture_decoration?.findIndex((x) => x[imageKey]) !== -1
      ? picture_decoration?.findIndex((x) => x[imageKey])
      : -1;
  let decorations = [];
  if (dIndx !== -1) {
    decorations = picture_decoration[dIndx][imageKey];
  }

  var pictureWidth = imageWidth;
  var pictureHeight = imageHeight;
  var boxWidth = window.innerWidth;
  var boxHeight = window.innerHeight;
  var newPictureWidth;
  var newPictureHeight;
  

  if (pictureWidth > boxWidth) {
    newPictureHeight = (pictureHeight * boxWidth) / pictureWidth;
    if (newPictureHeight > boxHeight) {
      newPictureWidth = (pictureWidth * boxHeight) / pictureHeight;
      newPictureHeight = boxHeight;
    } else {
      newPictureWidth = boxWidth;
      // Use width as bounding
    }
  } else if (pictureHeight > boxHeight) {
    newPictureWidth = (pictureWidth * boxHeight) / pictureHeight;
    newPictureHeight = boxHeight;
    // Use height for bounding
  } else {
    newPictureWidth = pictureWidth;
    newPictureHeight = pictureHeight;
    // CenterPicture;
  }

  if (document.getElementById(`svg-designs-${currIndx}`) !== null) {
    boxWidth = parseInt(
      document.getElementById(`svg-designs-${currIndx}`).width.baseVal.value
    );
    boxHeight = parseInt(
      document.getElementById(`svg-designs-${currIndx}`).height.baseVal.value
    );
  }

  return (
    <div style={{ pointerEvents: "none" }} id="scrolldisable">
      <svg
        id={`svg-designs-${currIndx}`}
        style={{ width: `${newPictureWidth}`, height: `${newPictureHeight}` }}
        className={matches ? "svg-clickable1" : "svg-clickable2"}
      >
        {decorations.length !== 0
          ? decorations.map((markings) => {
              let lineAndArrow = drawLineAndArrow(
                newPictureWidth,
                newPictureHeight,
                newPictureWidth,
                newPictureHeight,
                markings.decoration_begin_horizontal,
                markings.decoration_end_horizontal,
                markings.decoration_begin_vertical,
                markings.decoration_end_vertical
              );
              let drawEclipseContent = drawEclipse(
                newPictureWidth,
                newPictureHeight,
                newPictureWidth,
                newPictureHeight,
                markings.decoration_begin_horizontal,
                markings.decoration_end_horizontal,
                markings.decoration_begin_vertical,
                markings.decoration_end_vertical
              );
              let drawTextContent = drawText(
                newPictureWidth,
                newPictureHeight,
                newPictureWidth,
                newPictureHeight,
                parseInt(markings.decoration_begin_horizontal),
                parseInt(markings.decoration_begin_vertical),
                markings.decoration_text
              );

              const splitChar = (paragraph) => {
                let paragraphWithBreaks = paragraph
                  .split(" ")
                  .reduce((accum, curr, idx) => {
                    if (idx && (idx + 1) % 3 === 1) {
                      return [...accum, "`", curr];
                    } else return [...accum, curr];
                  }, [])
                  .join(" ");
                console.log(paragraphWithBreaks.split(/[`]/));
                return paragraphWithBreaks.split(/[`]/);
              };
              return (
                <>
                  {parseInt(markings.decoration_type) === 0 ? (
                    <>
                      <line
                        x1={lineAndArrow.LineX1}
                        y1={lineAndArrow.LineY1}
                        x2={lineAndArrow.LineX2}
                        y2={lineAndArrow.LineY2}
                        style={{
                          stroke: markings.decoration_color.startsWith("#")
                            ? markings.decoration_color
                            : `#${markings.decoration_color}`,
                        }}
                        strokeWidth={lineAndArrow.strokeWidth}
                      />
                      <line
                        x1={lineAndArrow.arrowStartX1}
                        y1={lineAndArrow.arrowStartY1}
                        x2={lineAndArrow.LineX2}
                        y2={lineAndArrow.LineY2}
                        style={{
                          stroke: markings.decoration_color.startsWith("#")
                            ? markings.decoration_color
                            : `#${markings.decoration_color}`,
                        }}
                        strokeWidth={lineAndArrow.strokeWidth}
                      />
                      <line
                        x1={lineAndArrow.arrowStartX2}
                        y1={lineAndArrow.arrowStartY2}
                        x2={lineAndArrow.LineX2}
                        y2={lineAndArrow.LineY2}
                        style={{
                          stroke: markings.decoration_color.startsWith("#")
                            ? markings.decoration_color
                            : `#${markings.decoration_color}`,
                        }}
                        strokeWidth={lineAndArrow.strokeWidth}
                      />
                    </>
                  ) : parseInt(markings.decoration_type) === 1 ? (
                    <>
                      <ellipse
                        fill="none"
                        cx={Math.abs(drawEclipseContent.Vcx)}
                        cy={Math.abs(drawEclipseContent.Vcy)}
                        rx={Math.abs(
                          drawEclipseContent.Vrx === 0
                            ? 1
                            : drawEclipseContent.Vrx
                        )}
                        ry={Math.abs(drawEclipseContent.Vry)}
                        style={{
                          stroke: markings.decoration_color.startsWith("#")
                            ? markings.decoration_color
                            : `#${markings.decoration_color}`,
                        }}
                        strokeWidth={drawEclipseContent.strokeWidth}
                      />
                    </>
                  ) : parseInt(markings.decoration_type) === 3 ? (
                    <>
                      <>
                        {drawTextContent?.textPlacements?.lineTexts?.map(
                          (text, index) => (
                            <text
                              id="getText1"
                              x={
                                drawTextContent?.textPlacements?.placements[
                                  index
                                ].x
                              }
                              y={
                                drawTextContent?.textPlacements?.placements[
                                  index
                                ].y
                              }
                              fill={markings.decoration_color}
                              className='svg__fonts'
                              style={{
                                fontSize:
                                  drawTextContent?.textPlacements?.fontSize,
                                fontWeight: 500,
                                strokeWidth: 0.3,
                                strokeLinejoin: "round",
                                strokeLinecap: "round",
                              }}
                            >
                              {text}
                            </text>
                          )
                        )}
                      </>{" "}
                      : ( "" )
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            })
          : ""}
      </svg>
    </div>
  );
};
